import React, { Component } from 'react';
import { Card, Button, Modal, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport} from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from "react-datepicker";

import "./index.css";
import { Link } from 'react-router-dom';
class ListApiKeyHistoricalSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isButtonDisabled: [],
			loading: false,
            summaryList : [],
			startDate: '',
            endDate: ''
		}
	}
	componentDidMount() {
		this.getApiKeySummary();
	}

    getApiKeySummary = () => {
		const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : this.state.startDate,
            endDate : this.state.endDate,
            username: this.state.username
        }
		// Fetcher.get(this.props.app.apiPath + '/api/obd/apikey/historical/summary/'+userId)
		// 	.then(res => {
		// 		this.setState({
		// 			summaryList: res
		// 		})
		// 	})
		// 	.catch(err => { console.log('Error in fetching Api Key Summary', err) });

		Fetcher.post(this.props.app.apiPath + '/api/obd/apikey/historical/summary', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
                console.log(res);
				this.setState({
					summaryList: res
				})
			})
			.catch(err => {
				console.log('Error in fetching Api Key Historical Summary', err)
				Notify.notify('Error in fetching Api Key Historical Summary');
			});
	}


	refreshPage = () => {
		this.setState({ loading: true });
		setTimeout(() => {
			window.location.reload(false);
		}, 500);
	}


	setMessageShow = (status) => {
		this.setState({
			showEditChannelModal: false,
		});
	}

	editChannels = (event) => {
		event.preventDefault();
		// const data = {
		// }
		return Fetcher.post(this.props.app.apiPath + '/api/obd/edit/channels/'+this.state.campId+'/'+this.state.channels, { headers: { "Content-type": "application/json" }, method: 'POST' })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Edit Channels Failed');
				} else {
					Notify.notify(res.message);
				}
				this.refreshPage();
			})
			.catch(err => {
				console.log('Error in Edit Channesl', err)
				Notify.notify('Error in Edit Channels');
			});
	}

	confirmEditChannels = (status, campaignId) => {
		this.setState({
			showEditChannelModal: status,
			campId: campaignId
		});
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	handleStartDateChange = (date) => {
               this.setState({
                   startDate: date,
            });
     };

     handleEndDateChange = (date) => {
               this.setState({
                   endDate: date,
            });
     };

	 formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

	generateReport = (index, apiId, reportType) => {
		const data = {
			"apiId": apiId,
			"reportType": reportType
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/api/report/generate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {

				alert(res.message);
				this.getApiKeySummary();
				
			})
			.catch(err => { console.log('Error in Generating Report', err) });
	}

	 searchCdr = async (e) => {
        e.preventDefault();
        let sDate = "";
        let eDate ="";
        if(this.state.startDate === ""){
                 sDate = this.state.startDate
        }else{
            sDate =  this.formatDate(this.state.startDate)
        }
        if(this.state.endDate === ""){
                 eDate = this.state.endDate
        }else{
            eDate =  this.formatDate(this.state.endDate)
        }

        const userId = this.props.app.userId;

        const data = {
            startDate: sDate,
            endDate: eDate,
            userId: userId
        }

	 

        return Fetcher.post(this.props.app.apiPath + '/api/obd/apikey/historical/summary', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
            .then(res => {
                this.setState({'summaryList': res});
                if(res.length == 0) this.setState({noDataDisplayMsg: 'No records found'});
            })
            .catch(err => {
                console.log('Error in fetching Api Historicl Summary: ', err);
                Notify.notify('Error in fetching Api Historicl Summary');
            });
    }

	render() {

		const summary  = this.state.summaryList;
		const campaigns = this.state.campaignlist;
		const columns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true,
				csvExport: false
			},
			{
				dataField: 'userId',
				text: 'User Id',
				sort: false,
				hidden: false,
				csvExport: true
			},
			{
				dataField: 'apiKey',
				text: 'Api Key',
				sort: true,
				searchable: false,
				csvExport: true
			},
			{
				dataField: 'scheduleDate',
				text: 'Schedule Date ',
				sort: true,
				searchable: true,
				csvExport: false
			},
			
			{
				dataField: 'nosProcessed',
				text: 'Nos Processed',
				sort: true,
				searchable: true,
				hidden: true,
				csvExport: true
			}, {
				dataField: 'totalNos',
				text: 'Total Nos',
				sort: true,
				searchable: true
			},
			{
				dataField: 'dndCount',
				text: 'Dnd Count',
				sort: true,
				searchable: true
			}, {
				dataField: 'pulses',
				text: 'Pulses',
				sort: true,
				searchable: true
			},{
				dataField: 'connectedCalls',
				text: 'Connected Calls',
				sort: true,
				searchable: false
			},{
				dataField: 'dtmfCount',
				text: 'DTMF Count',
				sort: true,
				searchable: false
			}, {
				dataField: 'retryCount',
				text: 'Retry Count',
				sort: false,
				searchable: false
			}, {
				dataField: 'parentId',
				text: 'Parent Id',
				sort: false,
				searchable: false,
				csvExport: false
			},{
				dataField: 'parentPulseDuration',
				text: 'Parent Pulse Duration',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'sParentId',
				text: 'S Parent ID',
				sort: false,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmf1Count',
				text: 'Dtmf1 Count',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'dtmf2Count',
				text: 'Dtmf2 Count',
				sort: true,
				searchable: false
			},{
				dataField: 'autoRetryCount',
				text: 'Autor Retry Count',
				sort: true,
				searchable: false
			},{
				dataField: 'smsCount',
				text: 'Sms Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'parentRefund',
				text: 'Parent Refund',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'dtmf1CountCsv',
				text: 'Dtmf1 Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},
			{
				dataField: 'fullReportStatus',
				text: 'Action',
				sort: false,
				searchable: false,
				csvExport: false
			},
		];

		const products = Array.isArray(summary) ? summary.map((summary, index) => {
			
			let action = [];
			let retry = [];
			//let 
			action.push(<Dropdown id="dd">
				<Dropdown.Toggle className='btn-sm'>Take Action</Dropdown.Toggle>
					<Dropdown.Menu>
						
						{ <Dropdown.Item id={`greport-${index}`} key={`greport-${index}`} onClick={() => this.generateReport(index, summary.id, 'api')} href="#">Generate Full Report</Dropdown.Item>}
						
					</Dropdown.Menu>
				  </Dropdown>);
		
		
			return {
				id: summary.id,
				userId: summary.userId,
				apiKey: summary.apiKey,
				scheduleDate: summary.scheduleDate,
				nosProcessed: summary.nosProcessed,
				totalNos: summary.totalNos,
				dndCount: summary.dndCount,
				scheduleTime: summary.scheduleTime,
				endTime: summary.endTime,
				pulses: summary.pulses,
				connectedCalls: summary.connectedCalls,
				dtmfCount: summary.dtmfCount,
				retryCount: summary.retryCount,
				parentId: summary.parentId,
				parentPulseDuration: summary.parentPulseDuration,
				sParentId: summary.sParentId,
				dtmf1Count: summary.dtmf1Count,
				dtmf2Count: summary.dtmf2Count,
				autoRetryCount: summary.autoRetryCount,
				smsCount: summary.smsCount,
				parentRefund: summary.parentRefund,
				apiKey: summary.apiKey,
				fullReportStatus: action
			};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'scheduleDate',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};

		return (
			<Card>
					<Card.Header>Api Key Summary</Card.Header>
					<Card.Body>
						<div className="campaign-list-table-cont">



                          <Form className="add-campaign-form" method='post' onSubmit={(e) => this.searchCdr(e)}>
                            <Row>
                                {/* <Col sm={12}>
                                    <Row> */}
                                        
                                        <Col sm={4}>
                                            <Form.Group className="form-group col-md-6 float-left">
					                        <DatePicker
						                             className="form-control"
						                             dateFormat="yyyy-MM-dd"
                                                     selected={this.state.startDate}
                                                     onChange={this.handleStartDateChange}
                                                    placeholderText="Start Date"
					                       />
				                           </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                           <Form.Group className="form-group col-md-6 float-left">
					                        <DatePicker
						                             className="form-control"
						                             dateFormat="yyyy-MM-dd"
						                             selected={this.state.endDate}
                                                     onChange={this.handleEndDateChange}
                                                     placeholderText="End Date"
					                       />
				                           </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Button variant="primary" type="submit">Search</Button>
                                        </Col>
                                    </Row>
                                {/* </Col>
                            </Row> */}
                        </Form>
                        <br/>

							{/* <h3>Campaign Summary</h3> */}
							{/*{!isEmpty( this.state.campaignlist ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
							{<PaginationProvider pagination={paginationFactory(paginationOption)}>
								{({
									paginationProps, paginationTableProps
								}) => (<ToolkitProvider
									keyField="id"
									data={products}
									columns={columns}
									search
									bootstrap4
									exportCSV={{
										fileName: 'api_key_summary.csv'
									}}
								>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
										<Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.refreshPage()}>Refresh</Button>
										<hr />
										<BootstrapTable bordered={true} hover defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="w-auto campaignlist-table" {...props.baseProps} {...paginationTableProps} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)}
							</PaginationProvider>}
						</div>

						{(this.state.loading === true) && <div className="fetching-data"><img alt="" src="/assets/loading.gif" /></div>}
					</Card.Body>
				</Card>
		);
	}
}


export default ListApiKeyHistoricalSummary;